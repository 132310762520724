/* eslint-disable max-classes-per-file */
import { configureStore } from '@reduxjs/toolkit';
import { frontRoyalStoreApi } from 'FrontRoyalStore';
import { backRoyalApi } from 'BackRoyalApi';
import { cohortsApi } from 'Cohorts';
import { chatSlice } from 'TutorBot';
import { themeSlice } from 'Theme';
import { storeProvider, combineApisAndSlices, apiProvider } from 'ReduxHelpers';
import { autoSuggestOptionsApi } from 'AutoSuggestOptions';
import { careerProfilesApi } from 'CareerProfiles';
import { lessonsApi, lessonsEditorApi } from 'Lessons';
import { playlistExamEvaluationsApi } from 'PlaylistExamEvaluation';
import { resourcesApi } from 'Resources/resourcesApi';
import { editAoiRecordsApi } from 'UserAdministrationTab';
import { playlistEditorApi } from 'Playlists';
import { messagingSlice } from 'Messaging';
import { customMiddleware } from './middleware';

// the `as` here is a total hack, but I don't think it's gonna cause any issues
const apis = [
    autoSuggestOptionsApi,
    backRoyalApi,
    careerProfilesApi,
    cohortsApi,
    editAoiRecordsApi,
    frontRoyalStoreApi,
    lessonsApi,
    lessonsEditorApi,
    playlistEditorApi,
    playlistExamEvaluationsApi,
    resourcesApi,
] as typeof backRoyalApi[];

const slices = [chatSlice, themeSlice, messagingSlice];

export function initializeAppStore() {
    if (storeProvider.store) {
        throw new Error('store is already defined');
    }

    apiProvider.add(apis);
    const { reducer, middleware } = combineApisAndSlices({ apis, slices, middleware: customMiddleware });

    const store = configureStore({
        reducer,
        middleware,
    });

    storeProvider.store = store;

    return store;
}

// In lesson preview mode, the storeProvider is attached to the iframe.contentWindow
export const getState = () => (window.storeProvider ?? storeProvider).store!.getState();
