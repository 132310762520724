/* eslint-disable react/no-unknown-property */
import { memo, createElement, type FC, useRef, useEffect, useState } from 'react';
import { FrontRoyalRouter } from 'FrontRoyalRouter';
import { angularInjectorProvider } from 'Injector';
import { initializeAppStore } from 'FrontRoyalRedux';
import { createPortal } from 'react-dom';
import { useWaitFor } from 'useWaitFor';
import { bootstrapFrontRoyalAngularApp } from './bootstrapFrontRoyalAngularApp';
import { ReactWrapper } from './ReactWrapper';

const store = initializeAppStore();

function useWaitForAngularInjector() {
    return useWaitFor(() => angularInjectorProvider.injector);
}

function RouterPortal() {
    // react-target is created in app_shell.html
    const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(document.getElementById('react-target'));
    const angularInjector = useWaitForAngularInjector();
    const observerRef = useRef<MutationObserver | null>(null);

    useEffect(() => {
        const updateTarget = () => {
            const reactTarget = document.getElementById('react-target');
            if (reactTarget !== portalTarget) {
                setPortalTarget(reactTarget);
            }
        };

        updateTarget();

        // listen for html node changes and call updateTarget
        // this is only necessary because react-target might not always be on the screen
        // i.e logged out
        observerRef.current = new MutationObserver(() => {
            updateTarget();
        });

        observerRef.current.observe(document.querySelector('[ng-controller]')!, {
            childList: true,
            subtree: true,
        });

        return () => {
            observerRef.current?.disconnect();
        };
    }, [portalTarget]);

    return portalTarget && angularInjector
        ? createPortal(
              <ReactWrapper angularInjector={angularInjector} store={store} classNames="tw-h-full">
                  <FrontRoyalRouter />
              </ReactWrapper>,
              portalTarget,
          )
        : null;
}

const FrontRoyalRootComponent: FC = () => {
    const nodeRef = useRef<HTMLDivElement>(null); // Create a ref to the div element

    useEffect(() => {
        if (nodeRef.current) {
            bootstrapFrontRoyalAngularApp(nodeRef.current);
        }
    }, []);

    return (
        <>
            {/*
                The angular app and the react app are both on the screen at the same time, but at any particular
                route, one app or the other renders the content.

                The first element here will end up holding the angular app.
                The second element creates a portal that renders the react router into a target element
                    within the elements created in the angular layout. Once we move the app header and other
                    layout elements out to angular, we will no longer need a portal. At that point, react will
                    render the layout, and then the angular app and the react router can just sit next to each other.
            */}
            <div ref={nodeRef} ng-controller="SiteCtrl" ng-class="site()" ng-show="readyToShow">
                {createElement('front-royal-wrapper')}
            </div>
            <RouterPortal />
        </>
    );
};

export const FrontRoyalRoot = memo(FrontRoyalRootComponent) as typeof FrontRoyalRootComponent;
