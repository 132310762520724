import 'FrontRoyalAngular/angularModule';

// It's strange that this angular-related function is in a module called `FrontRoyalReact`, but it has
// to be in a module that does not get touched in storybook, so it can't be in `FrontRoyalAngular`.
// It can't be loaded in storybook because the import of angularModule requires angular, which is not
// available in storybook.
export function bootstrapFrontRoyalAngularApp(appElement) {
    // wait for deviceready in Cordova
    if (window.CORDOVA) {
        // according to the docs: "The deviceready event behaves somewhat differently from others. Any event handler registered after the
        // deviceready event fires has its callback function called immediately."
        document.addEventListener('deviceready', () => {
            angular.bootstrap(appElement, [window.MANUAL_BOOTSTRAP_MODULE]);
        });
    } else {
        angular.bootstrap(appElement, [window.MANUAL_BOOTSTRAP_MODULE]);
    }
}
