import { createRoot } from 'react-dom/client';
import { FrontRoyalRoot } from './FrontRoyalRoot';
import { bootstrapFrontRoyalAngularApp } from './bootstrapFrontRoyalAngularApp';

export function bootstrapFrontRoyalApp() {
    // This element is put on the screen in index.html.erb
    const mainAppNode = document.getElementById('app-root');
    if (mainAppNode) {
        const root = createRoot(mainAppNode!);

        root.render(<FrontRoyalRoot />);
    } else {
        // If mainAppNode does not exist, then we are likely hitting one of the old marketing pages
        // locally and need to bootstrap the app to where the ng-controller is expected
        // see marketing.html.erb
        bootstrapFrontRoyalAngularApp(document.querySelector('body'));
    }
}
